import React from "react";
import {
    H3, Hr,
} from '@bootstrap-styled/v4';
import { MiniHr } from "../../components/style";


const Home = () => {

    return (
        <>
            <H3>G.Lab Tools</H3>
            <Hr />
            <p>Olá, bem-vinde ao G.Lab Tools. Por aqui, você poderá criar chamadas e natives para os conteúdos de seus projetos, bem como URLs parametrizadas.</p>

            <p>Basta selecionar a ferramenta que você está procurando e preencher os campos necessários. Depois de preenchidos, clique no botão para gerar, copie o texto e pronto, pode enviar para quem precisar :)</p>

            <MiniHr />

            <p>Se por acaso você encontrar algum bug ou problema, não se preocupe! Basta preencher o <a href="https://forms.gle/b5X2YCKpFcdcFxtP6" target="_blank">Formulário de Relatório de Bugs</a> com o máximo de detalhamento possível. Seu feedback é essencial 🙂</p>

            <p>Caso queira saber quais funcionalidades e melhorias estão sendo desenvolvidas para esta ferramenta, confira o <a href="https://tiffs.notion.site/5721f9ba00fd4c338965d4e50fb64976?v=7f4d03a8e9fc456f966b00199ccc9dd5" target="_blank">Roadmap de Desenvolvimento</a>.</p>

        </>
    )

}

export default Home;