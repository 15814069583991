import React, { useEffect, useState } from "react";
import Select from 'react-select';
import ParamUrlForm from "../../param-url/form.js";
import {
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Small,
    A,
    Tooltip
} from "@bootstrap-styled/v4";
import { AlertStyle } from "../../../../components/style.js";
import { HelpIcon } from "../../../../components/icons.js";
import tips from "../../../../components/tips.js";


const Natives = (props) => {

    const [impressoes, setImpressoes] = useState("500 mil");
    const [print, setPrint] = useState({ label: "Sim", value: true });

    const [campaignSource, setCampaignSource] = useState("native");
    const [campaignMedium, setCampaignMedium] = useState("native");

    const [copyParamUrl, setCopyParamUrl] = useState("");

    const [sites, setSites] = useState([]);
    const [clusters, setClusters] = useState([]);

    const [limiteCaracteresTitulo, setLimiteCaracteresTitulo] = useState(50);
    const [limiteCaracteresApresentado, setLimiteCaracteresApresentado] = useState(40);

    const [tooltipApresentado, setTooltipApresentado] = useState({ isOpen: false });
    const [tooltipClusters, setTooltipClusters] = useState({ isOpen: false });
    const [tooltipSites, setTooltipSites] = useState({ isOpen: false });

    useEffect(() => {
        var tmpParamUrl = `${props.url}?utm_source=${campaignSource.split(' ').join('+')
            }&utm_medium=${campaignMedium.split(' ').join('+')}&utm_campaign=${props.campaignName.split(' ').join('+')}`;
        setCopyParamUrl(tmpParamUrl);
    }, [props, campaignSource, campaignMedium]);

    useEffect(() => {
        let tmpText = `Título: ${props.titulo}<br />Apresentado por: ${props.cliente}<br />Impressões: ${impressoes}<br />Precisa de print: ${print.label}<br />URL parametrizada: ${copyParamUrl}<br />Sites: ${sites.join(", ")}<br />Clusters: ${clusters.join(", ")}`;
        props.setNativesTextToCopy(tmpText);
    }, [impressoes, print, copyParamUrl, sites, clusters, props]);


    const printLista = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' }
    ];

    const sitesLista = [
        { value: 'oGlobo', label: 'O Globo' },
        { value: 'extra', label: 'Extra' },
        { value: 'valorEconomico', label: 'Valor Econômico' },
        { value: 'valorInveste', label: 'Valor Investe' },
        { value: 'autoesporte', label: 'Autoesporte' },
        { value: 'marieClaire', label: 'Marie Claire' },
        { value: 'quem', label: 'Quem' },
        { value: 'monet', label: 'Monet' },
        { value: 'casaEJardim', label: 'Casa & Jardim' },
        { value: 'epocaNegocios', label: 'Época Negócios' },
        { value: 'globoRural', label: 'Globo Rural' },
        { value: 'pegn', label: 'PEGN' },
        { value: 'crescer', label: 'Crescer' },
        { value: 'galileu', label: 'Galileu' },
        { value: 'techtudo', label: 'Techtudo' },
    ];

    const sitesTodos = { value: 'todos', label: 'Todos' };

    let sitesListaOrdenados = sitesLista.sort((a, b) => (a.value > b.value) ? 1 : -1);
    sitesListaOrdenados.unshift(sitesTodos);


    const handleChangeSites = (selectedOptions) => {
        let sitesTmp = [];
        selectedOptions.map(s => (sitesTmp.push(s.label)));
        console.log(sitesTmp)
        setSites(sitesTmp);
    }

    const handleChangeClusters = (selectedOptions) => {
        let clustersTmp = [];
        selectedOptions.map(c => (clustersTmp.push(c.label)));
        console.log(clustersTmp)
        setClusters(clustersTmp);
    }

    const handleChangePrint = (selectedOption) => {
        setPrint(selectedOption);
    }

    const clustersLista = [
        { value: 'agronegocio', label: 'Agronegócio' },
        { value: 'alimentacaoInfantil', label: 'Alimentação infantil' },
        { value: 'automotivo', label: 'Automotivo' },
        { value: 'automotivoLuxo', label: 'Automotivo Luxo' },
        { value: 'belezaHigiene', label: 'Beleza e higiene' },
        { value: 'brinquedoInfantil', label: 'Brinquedo infantil' },
        { value: 'casaDecoracao', label: 'Casa e decoração' },
        { value: 'cervejas', label: 'Cervejas' },
        { value: 'cervejasEspeciais', label: 'Cervejas especiais' },
        { value: 'culturaLazer', label: 'Cultura e lazer' },
        { value: 'economiaFinancas', label: 'Economia e finanças' },
        { value: 'educacao', label: 'Educação' },
        { value: 'educacaoSuperiorPos', label: 'Educação - Ensino superior e pós' },
        { value: 'educacaoInfantil', label: 'Educação infantil' },
        { value: 'educacaoVestibular', label: 'Educação - Vestibular' },
        { value: 'energia', label: 'Energia' },
        { value: 'esg', label: 'ESG' },
        { value: 'esporte', label: 'Esporte' },
        { value: 'gastronomiaALimentacao', label: 'Gastronomia e alimentação' },
        { value: 'gravidez', label: 'Gravidez' },
        { value: 'imobiliarioLuxo', label: 'Imobiliário luxo' },
        { value: 'imobiliarioPopular', label: 'Imobiliário popular' },
        { value: 'luxo', label: 'Luxo' },
        { value: 'maternidade', label: 'Maternidade' },
        { value: 'moda', label: 'Moda' },
        { value: 'modaInfantil', label: 'Moda infantil' },
        { value: 'negociosEmpreendedorismo', label: 'Negócios e empreendedorismo' },
        { value: 'paisagismo', label: 'Paisagismo' },
        { value: 'perfilInvestidor', label: 'Perfil investidor' },
        { value: 'pets', label: 'Pets' },
        { value: 'saudeBemEstar', label: 'Saúde e bem-estar' },
        { value: 'sustentabilidade', label: 'Sustentabilidade' },
        { value: 'tecnologia', label: 'Tecnologia' },
        { value: 'telefonia', label: 'Telefonia' },
        { value: 'turismo', label: 'Turismo' },
        { value: 'turismoInternacional', label: 'Turismo internacional' },
        { value: 'turismoNacional', label: 'Turismo nacional' },
        { value: 'vinho', label: 'Vinho' },
    ];

    const clustersListaOrdenados = clustersLista.sort((a, b) => (a.value > b.value) ? 1 : -1);



    // const handleClearForm = () => {
    //     setTitulo("");
    //     setApresentado("");
    //     setImpressoes("500 mil");
    //     setPrint({value: true, label: "Sim"});
    //     setUrl("");
    //     setCampaignSource("");
    //     setCampaignMedium("");
    //     setCampaignName("");
    //     setSites([]);
    //     setClusters([]);
    // };

    return (
        <>
            <AlertStyle color="info">

                <ul>
                    <li>Título: {limiteCaracteresTitulo} caracteres</li>
                    <li>Imagem: 300x160px 150KB</li>
                    <li>Cliente: {limiteCaracteresApresentado} caracteres</li>
                    <li>Caso não seja indicada a quantidade de impressões, a entrega será feita com o padrão de 500 mil.</li>
                    <li>Formato exclusivo para projeto de conteúdo e que não pode ser vendido separadamente.</li>
                </ul>
            </AlertStyle>


            <Row>
                <Col>
                    <FormGroup row>
                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Título:</Label>

                            <Input
                                type="text"
                                className="form-control"
                                value={props.titulo}
                                onChange={e => props.setTitulo(e.target.value)}
                            />

                            {props.titulo.length > limiteCaracteresTitulo ? <Small color="danger">{props.titulo.length} caracteres (limite: {limiteCaracteresTitulo})</Small> : <Small>{props.titulo.length} caracteres</Small>}
                        </Col>

                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Apresentado por: <A id="tooltip-apresentado"><HelpIcon /></A></Label>

                            <Tooltip placement="right" isOpen={tooltipApresentado.isOpen} target="tooltip-apresentado" toggle={() => setTooltipApresentado({ isOpen: !tooltipApresentado.isOpen })}>
                                {tips.apresentadoPor}
                            </Tooltip>

                            <Input type="text" className="form-control"
                                value={props.cliente}
                                onChange={e => props.setCliente(e.target.value)}
                            />
                            {props.cliente.length > limiteCaracteresApresentado ? <Small color="danger">{props.cliente.length} caracteres (limite: {limiteCaracteresApresentado})</Small> : <Small>{props.cliente.length} caracteres</Small>}

                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Impressões:</Label>

                            <Input
                                type="text"
                                className="form-control"
                                value={impressoes}
                                onChange={e => setImpressoes(e.target.value)}
                            />
                        </Col>

                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Precisa de Print:</Label>

                            <Select
                                value={print}
                                options={printLista}
                                onChange={handleChangePrint}
                            />

                        </Col>

                    </FormGroup>

                    <ParamUrlForm
                        url={props.url}
                        setUrl={props.setUrl}
                        campaignSource={campaignSource}
                        setCampaignSource={setCampaignSource}
                        campaignMedium={campaignMedium}
                        setCampaignMedium={setCampaignMedium}
                        campaignName={props.campaignName}
                        setCampaignName={props.setCampaignName}
                        elementId="natives"
                    />

                    <FormGroup row>
                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Sites: <A id="tooltip-sites"><HelpIcon /></A></Label>

                            <Tooltip placement="right" isOpen={tooltipSites.isOpen} target="tooltip-sites" toggle={() => setTooltipSites({ isOpen: !tooltipSites.isOpen })}>
                                {tips.sites}
                            </Tooltip>

                            <Select
                                options={sitesListaOrdenados}
                                closeMenuOnSelect={false}
                                onChange={handleChangeSites}
                                isMulti
                            />
                        </Col>


                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Clusters: <A id="tooltip-clusters"><HelpIcon /></A></Label>

                            <Tooltip placement="right" isOpen={tooltipClusters.isOpen} target="tooltip-clusters" toggle={() => setTooltipClusters({ isOpen: !tooltipClusters.isOpen })}>
                                {tips.clusters}
                            </Tooltip>

                            <Select
                                options={clustersListaOrdenados}
                                closeMenuOnSelect={false}
                                onChange={handleChangeClusters}
                                isMulti
                            />

                        </Col>

                    </FormGroup>

                </Col>
            </Row >

        </>
    )
}

export default Natives;
