import React, { useState } from 'react';

import Menu from './menu';
import { NavbarItemStyle, NavbarStyle } from './style';
import { MenuIcon } from './icons';

import { Collapse } from '@bootstrap-styled/v4';

const CompHeader = () => {

    const [initialState, setInitialState] = useState(false);

    return(
        <>
            <MenuIcon onClick={() => setInitialState(!initialState)} />

            <Collapse isOpen={initialState}>
                    
                <NavbarStyle className="navbar navbar-expand-xl navbar-light bg-light visible-xs">
                    <div className="d-flex justify-content-between">
                        <NavbarItemStyle>
                            <ul className="nav navbar-nav ml-auto">
                                <Menu />
                            </ul>
                        </NavbarItemStyle>
                    </div>
                </NavbarStyle>
            </Collapse>

        </>
    )
}

export default CompHeader;