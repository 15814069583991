const pallete = {
    red: '#E94A45',
    purple: '#8A2966',
    lightBlue: '#90BEE2',
    darkBlue: '#5D57A4',
    white: '#ffffff'
};

export const theme = {
    colors: {
        background: pallete.white,
        primary: pallete.purple,
        secondary: pallete.red,
        default: pallete.lightBlue,
        success: pallete.darkBlue, 
        danger: pallete.red,
        failure: pallete.red,
        white: pallete.white,
    }
}