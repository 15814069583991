import React, { useState, useRef,  } from "react";

import {
    FormGroup,
    Label,
    Input,
    Col,
    A,
    Tooltip
} from "@bootstrap-styled/v4";
import { Fieldset } from "@bootstrap-styled/v4";
import { HelpIcon } from "../../../components/icons.js";
import tips from "../../../components/tips.js";

const ParamUrlForm = (props) => {

    const [tooltipCampaignName, setTooltipCampaignName] = useState({isOpen: false});
    const [tooltipCampaignNameId, setTooltipCampaignNameId] = useState("tooltip-campaign-name-" + props.elementId);


    return (
    <>
    <Fieldset>
                <FormGroup row>
                    <Col lg={6} md={12}>
                        <Label htmlFor="inline-form-input-group">URL:</Label>

                        <Input
                            type="text"
                            className="form-control"
                            defaultValue={props.defaultUrl}
                            onChange={e => props.setUrl(e.target.value)}
                        />
                    </Col>

                    <Col lg={6} md={12}>
                        <Label htmlFor="inline-form-input-group">Campaign source:</Label>

                        <Input type="text" className="form-control"
                            value={props.campaignSource}
                            onChange={e => props.setCampaignSource(e.target.value)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col lg={6} md={12}>
                        <Label htmlFor="inline-form-input-group">Campaign medium:</Label>

                        <Input
                            type="text"
                            className="form-control"
                            value={props.campaignMedium}
                            onChange={e => props.setCampaignMedium(e.target.value)}
                        />
                    </Col>

                    <Col lg={6} md={12}>
                        <Label htmlFor="inline-form-input-group">Campaign name: <A id={tooltipCampaignNameId}><HelpIcon /></A></Label>

                        <Tooltip placement="right" isOpen={tooltipCampaignName.isOpen} target={tooltipCampaignNameId} toggle={() => setTooltipCampaignName({ isOpen: !tooltipCampaignName.isOpen })}>
                            {tips.campaignName}
                        </Tooltip>

                        <Input type="text" className="form-control"
                            defaultValue={props.defaultCampaignName}
                            onChange={e => props.setCampaignName(e.target.value)}
                        />
                    </Col>
                </FormGroup>


    </Fieldset>

        
    </>
    )
};

export default ParamUrlForm;