import React, { useEffect, useState } from "react";

import {
    ButtonPrimary,
    ButtonDanger,
} from "../../../components/style.js";
import {
    H4,
    Form,
    Hr,
    Row,
    Col,
} from "@bootstrap-styled/v4";
import ParamUrlForm from "./form";
import ModalCopy from "../modal/modal.js";

const ParamUrlForms = () => {

    const [paramUrl, setParamUrl] = useState({
        url: '', campaignSource: '', campaignMedium: '', campaignName: ''
    });

    const [url, setUrl] = useState("");
    const [campaignSource, setCampaignSource] = useState("");
    const [campaignMedium, setCampaignMedium] = useState("");
    const [campaignName, setCampaignName] = useState("");

    const [copyParamUrl, setCopyParamUrl] = useState("");

    const [modalState, setModalState] = useState(false);
    const [alertState, setAlertState] = useState(false);

    const elementId = "param-url";


    const handleCloseModal = () => setModalState(!modalState);


    const handleSubmit = event => {

        setAlertState(false);

        var tmpParamUrl = `${url}?utm_source=${campaignSource.split(' ').join('+')
            }&utm_medium=${campaignMedium.split(' ').join('+')}&utm_campaign=${campaignName.split(' ').join('+')}`;

        setCopyParamUrl(tmpParamUrl);

        handleCloseModal();

        console.log(tmpParamUrl);
        event.preventDefault();

    };

    return(

        <>
            <Form onSubmit={handleSubmit}>

                <ParamUrlForm
                    paramUrl={paramUrl}
                    setParamUrl={setParamUrl}

                    url={url}
                    setUrl={setUrl}
                    campaignSource={campaignSource}
                    setCampaignSource={setCampaignSource}
                    campaignMedium={campaignMedium}
                    setCampaignMedium={setCampaignMedium}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    pageName="URL Parametrizada"
                    elementId={elementId}
                />

                <ButtonPrimary
                    type="submit">Gerar URL parametrizada</ButtonPrimary>


            </Form>


            <ModalCopy
                modalState={modalState}
                setModalState={setModalState}
                textToCopy={copyParamUrl}
                alertState={alertState}
                setAlertState={setAlertState}
                pageName="URL Parametrizada" />

        </>

    )
}

export default ParamUrlForms;