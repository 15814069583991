import React from 'react';
import { Hr, P, Small } from '@bootstrap-styled/v4';

const CompFooter = () => {

    return (
        <>
            <Hr />
            <P className="mx-auto">
                <Small>
                    G.Lab Tools v_1.2.2
                </Small>
            </P>
        </>
    );
};

export default CompFooter;
