import React, { useEffect, useState } from "react";
import Alert from "../../../components/alert";
import { ButtonNeutral } from "../../../components/style";
import NativesChamadasForms from "./form";

const NativesChamadas = () => {

    useEffect(() => {
        document.title = 'Natives & Chamadas';
    });

    const [newKey, setNewKey] = useState();

    const [modalState, setModalState] = useState(false);

    const refresh = () => {
        setModalState(!modalState)

    }


    return(
        <div>

            <NativesChamadasForms key={newKey} />

            <ButtonNeutral onClick={refresh}>Limpar Tudo</ButtonNeutral>

            <Alert
            modalState={modalState}
            setModalState={setModalState}
            newKey={newKey}
            setNewKey={setNewKey}
             />



        </div>

    )
}

export default NativesChamadas;
