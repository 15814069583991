import React from 'react';

import CompHeader from './header.js';
import CompFooter from './footer.js';
import Sidebar from './sidebar.js';

const Layout = ({ children }) => {

    return (

        <React.Fragment>
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <div className="container-fluid">
                        <div id="top-header">
                            <CompHeader />
                        </div>
                        
                        {children}
                    </div>

                    <CompFooter />

                </div>
            </div>            
        </React.Fragment>

    );
};

export default Layout;