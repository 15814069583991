import React from "react";
import { Link } from 'react-router-dom';
import { NavItem, Hr } from '@bootstrap-styled/v4';

const Menu = () => {

    return(
        <>
            <NavItem>
                <Link to="/" className="nav-link"><b>Home</b></Link>
            </NavItem>

            <NavItem>
                <Link to="/natives-chamadas" className="nav-link">Natives e Chamadas</Link>
            </NavItem>
            
            <NavItem>
                <Link to="/param-url" className="nav-link">URL Parametrizada</Link>
            </NavItem>
        </>

    )
};

export default Menu;