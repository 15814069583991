import React, { useState, useEffect } from "react";

import { FormGroup, H6, Input, Label, Row, Col, Small } from "@bootstrap-styled/v4";
import Hr from "@bootstrap-styled/v4/lib/Hr";
import ParamUrlForm from "../../../param-url/form";
import Select from 'react-select';
import { AlertStyle } from "../../../../../components/style";


function ChamadaAutoesporteTematico(props) {

    const [veiculo, setVeiculo] = useState(props.veiculo);

    const [titulo, setTitulo] = useState(props.titulo);
    const [subtitulo, setSubtitulo] = useState("");
    const [cliente, setCliente] = useState(props.cliente);
    const [print, setPrint] = useState({ label: "Sim", value: true });
    const [url, setUrl] = useState(props.url);
    const [campaignSource, setCampaignSource] = useState("autoesporte");
    const [campaignMedium, setCampaignMedium] = useState("chamada");
    const [campaignName, setCampaignName] = useState(props.campaignName);
    const [copyParamUrl, setCopyParamUrl] = useState("");

    const elementId = "autoesporte-tematico";


    const printLista = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' }
    ];

    const handleChangePrint = (selectedOption) => {
        setPrint(selectedOption);
    };

    useEffect(() => {
        var tmpParamUrl = `${url}?utm_source=${campaignSource.split(' ').join('+')}&utm_medium=${campaignMedium.split(' ').join('+')}&utm_campaign=${campaignName.split(' ').join('+')}`;
        setCopyParamUrl(tmpParamUrl);
    }, [url, campaignSource, campaignMedium, campaignName]);

    useEffect(() => {
        let tmpText = `-- ${veiculo}<br />Título: ${titulo}<br />Subtítulo: ${subtitulo}<br />Cliente: ${cliente}<br />Precisa de print: ${print.label}<br />URL parametrizada: ${copyParamUrl}<br /><br />`;
        props.setText(tmpText);
    }, [veiculo, titulo, subtitulo, cliente, print, copyParamUrl, props]);


    return (
        <>
            <AlertStyle color="info">
                <H6>{props.veiculo}</H6>
                <Hr />
                <ul>
                    <li>Título: {props.limiteCaracteresTitulo} caracteres</li>
                    <li>Imagem: 320x150px 150KB</li>
                </ul>
            </AlertStyle>

            <Row>
                <Col>
                    <FormGroup row>
                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Título:</Label>

                            <Input
                                type="text"
                                className="form-control"
                                defaultValue={props.titulo}
                                onChange={e => setTitulo(e.target.value)} />
                            {titulo.length > props.limiteCaracteresTitulo ? <Small color="danger">{titulo.length} caracteres (limite: {props.limiteCaracteresTitulo})</Small> : <Small>{titulo.length} caracteres</Small>}
                        </Col>

                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Cliente:</Label>

                            <Input type="text" className="form-control"
                                defaultValue={props.cliente}
                                onChange={e => setCliente(e.target.value)} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Precisa de Print:</Label>

                            <Select
                                value={print}
                                options={printLista}
                                onChange={handleChangePrint} />

                        </Col>

                        <Col lg={6} md={12}>
                            <Label htmlFor="inline-form-input-group">Subtítulo:</Label>

                            <Input type="text" className="form-control"
                                defaultValue={subtitulo}
                                onChange={e => setSubtitulo(e.target.value)} />
                        </Col>

                    </FormGroup>


                    <ParamUrlForm
                        defaultUrl={props.url}
                        url={url}
                        setUrl={setUrl}
                        campaignSource={campaignSource}
                        setCampaignSource={setCampaignSource}
                        campaignMedium={campaignMedium}
                        setCampaignMedium={setCampaignMedium}
                        defaultCampaignName={props.campaignName}
                        campaignName={campaignName}
                        setCampaignName={setCampaignName}
                        elementId={elementId} />

                    <Hr />

                </Col>
            </Row>


        </>

    );
}

export default ChamadaAutoesporteTematico;