import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@bootstrap-styled/v4";
import { ButtonNeutral, ButtonPrimary } from "./style";

const Alert = (props) => {

    const handleCloseModal = () => props.setModalState(!props.modalState);

    const handleChangeKey = () => {
        props.setNewKey(Date.now());
        handleCloseModal();

    }



    return(

        <Modal size="sm" isOpen={props.modalState} toggle={() => handleCloseModal()}>
            <ModalHeader>Atenção</ModalHeader>
            <ModalBody>
                <p>Limpar TODOS os campos?</p>
            </ModalBody>
            <ModalFooter>
                <ButtonPrimary
                onClick={handleChangeKey}>Limpar</ButtonPrimary>
                <ButtonNeutral
                onClick={handleCloseModal}>Cancelar</ButtonNeutral>
            </ModalFooter>
        </Modal>
    )
}

export default Alert;