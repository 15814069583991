const tips = {
    //NATIVES
    apresentadoPor: "Nome do cliente.",
    clusters: "Direcionamento do native por interesse de grupos de leitores.",
    sites: "Preencher com, no mínimo, três sites; ou todos.",

    //PARAM URL
    campaignName: "Será utilizado para identificar os pageviews provenientes desse link.",

    //CHAMADAS
    canal: "Editoria/chapéu.",
    selo: "Logo do cliente ou do hub, se houver.",
    diariaOuCalhau: "Diária: Chamada todos os dias. Calhau: Chamada quando tiver espaço.",
    tempoDeLeitura: "Pegar informação na página do conteúdo ou na pré-visualização do publicador.",
    subtitulo: "Caso não tenha imagem, informar o subtítulo.",

};

export default tips;