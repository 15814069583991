import React, { useEffect, useState} from "react";

import { ButtonNeutral } from "../../../components/style.js"

import { H4,
    Form,
    Hr,
    Row,
    Col,
    } from "@bootstrap-styled/v4";


import ParamUrlForm from "./form";
import ModalCopy from "../modal/modal.js";
import ParamUrlForms from "./forms.js";
import Alert from "../../../components/alert.js";

const ParamUrl = () => {

    
    useEffect(() => {  
        document.title = 'URL Parametrizada';
    });

    const [newKey, setNewKey] = useState();

    const [modalState, setModalState] = useState(false);

    const refresh = () => {
        setModalState(!modalState)

    }



    return(
        <>

            <Row>
                <Col>
                    <H4>URL Parametrizada</H4>
                    <Hr />
                </Col>
            </Row>

            <Row>
                <Col>
                    <ParamUrlForms key={newKey} />
                    <ButtonNeutral onClick={refresh}>Limpar Tudo</ButtonNeutral>
                </Col>
            </Row>


            <Alert
                modalState={modalState}
                setModalState={setModalState}
                newKey={newKey}
                setNewKey={setNewKey}
            />
        </>
        



    
    )
}

export default ParamUrl;
