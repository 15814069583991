import styled from "styled-components";
import { theme } from "./theme";
import { Menu } from '@styled-icons/entypo/Menu';
import { ChevronDown } from '@styled-icons/entypo/ChevronDown';
import { ChevronUp } from '@styled-icons/entypo/ChevronUp';
import { Tools } from '@styled-icons/entypo/Tools';
import { Help } from '@styled-icons/entypo/Help';

export const MenuIcon = styled(Menu)`
    color: ${theme.colors.primary};
    width: 50px;
    display: block;
    margin-left: auto;

    &:hover {
        cursor: pointer;
    }
`;

export const DownIcon = styled(ChevronDown)`
    color: #212529;
    width: 30px;
    display: inline-block

`
export const UpIcon = styled(ChevronUp)`
    color: #212529;
    width: 30px;
    display: inline-block
`

export const ToolsIcon = styled(Tools)`
    color: #fff;
    height: 35px;
    margin: 5px;
`

export const HelpIcon = styled(Help)`
    width: 15px;
    color: #fff;
    background-color: ${theme.colors.primary};
    border: 2px solid ${theme.colors.primary};
    border-radius: 8px;
    cursor: pointer;

`