import React, { useEffect, useState } from "react";
import Natives from "./natives";

// import { DownIcon, UpIcon } from "../../../components/icons";

import {
    Collapse,
    H4,
    Hr,
    Row,
    Col,
    Form,
} from "@bootstrap-styled/v4";
import Chamadas from "./chamadas";
import { ButtonPrimary } from "../../../components/style";
import ModalCopy from "../modal/modal";

const NativesChamadasForms = () => {


    const [titulo, setTitulo] = useState("");
    const [cliente, setCliente] = useState("");
    const [url, setUrl] = useState("");
    const [campaignName, setCampaignName] = useState("");

    const [nativesState, setNativesState] = useState(true);
    const [chamadasState, setChamadasState] = useState(true);

    const [modalState, setModalState] = useState(false);
    const [alertState, setAlertState] = useState(false);

    const [nativesTextToCopy, setNativesTextToCopy] = useState("");
    const [chamadasTextToCopy, setChamadasTextToCopy] = useState("");

    const [textToCopy, setTextToCopy] = useState("");

    function createTextToCopy() {
        let tmpTextToCopy = `CHAMADA/NATIVE | ${cliente} | ${titulo}<br /><br />[NATIVES]<br />${nativesTextToCopy}<br /><br />[CHAMADAS]<br />${chamadasTextToCopy}`;

        setTextToCopy(tmpTextToCopy);
    };

    const handleCloseModal = () => setModalState(!modalState);

    const handleSubmit = (event) => {
        createTextToCopy();
        setAlertState(false);
        handleCloseModal();
        event.preventDefault();
    };



    return(
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col
                    // onClick={() => setNativesState(!nativesState)}
                    >
                        <H4>Natives
                            {/* {nativesState === true ? <DownIcon /> : <UpIcon />} */}
                        </H4>
                        <Hr />
                    </Col>
                </Row>

                <Collapse isOpen={nativesState}>
                    <Natives
                        titulo={titulo}
                        setTitulo={setTitulo}
                        cliente={cliente}
                        setCliente={setCliente}
                        url={url}
                        setUrl={setUrl}
                        campaignName={campaignName}
                        setCampaignName={setCampaignName}
                        nativesTextToCopy={nativesTextToCopy}
                        setNativesTextToCopy={setNativesTextToCopy} />
                </Collapse>

                <br />


                <Row>
                    <Col
                    // onClick={() => setChamadasState(!chamadasState)}
                    >
                        <H4>Chamadas
                            {/* {chamadasState === true ? <DownIcon /> : <UpIcon />} */}
                        </H4>
                        <Hr />
                    </Col>
                </Row>

                <Collapse isOpen={chamadasState}>
                    <Chamadas
                        titulo={titulo}
                        cliente={cliente}
                        url={url}
                        campaignName={campaignName}
                        chamadasTextToCopy={chamadasTextToCopy}
                        setChamadasTextToCopy={setChamadasTextToCopy} />
                </Collapse>

                <div style={{ padding: "15px" }}></div>

                <ButtonPrimary type="submit">Gerar Natives e Chamadas</ButtonPrimary>
            </Form>



            <ModalCopy
                modalState={modalState}
                setModalState={setModalState}
                textToCopy={textToCopy}
                setTextToCopy={setTextToCopy}
                alertState={alertState}
                setAlertState={setAlertState}
                pageName="Natives e Chamadas" />                             


        </div>
    )
};

export default NativesChamadasForms;