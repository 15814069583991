import styled from "styled-components";
import { theme } from "./theme";

import Alert from "@bootstrap-styled/v4/lib/Alert";
import { Hr, ModalBody } from "@bootstrap-styled/v4";

export const NavbarStyle = styled.nav`
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
`

export const NavbarItemStyle = styled.div`
li {
    background-color: ${theme.colors.white};
    margin: 5px;
    padding: 3px 7px;
    transition: all 0.1s;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid ${theme.colors.white};
    width: 80vw;

    color: ${theme.colors.primary}

    &:hover {
        border-bottom: 3px solid ${theme.colors.primary};
        color: ${theme.colors.white};
    }
}
`

export const ButtonPrimary = styled.button`
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary};
    padding: 9px;
    color: ${theme.colors.primary}

    &:hover {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        border: 0px;
        padding: 10px;
    }
`

export const ButtonSecondary = styled.button`
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.secondary};
    padding: 9px;
    color: ${theme.colors.secondary}


    &:hover {
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.white};
        border: 0px;
        padding: 10px;
    }
`
export const ButtonDanger = styled.button`
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.danger};
    padding: 9px;
    color: ${theme.colors.danger}

    &:hover {
        background-color: ${theme.colors.danger};
        color: ${theme.colors.white};
        border: 0px;
        padding: 10px;
    }
`


export const ButtonNeutral = styled.button`
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.success};
    padding: 9px;
    color: ${theme.colors.success}

    &:hover {
        background-color: ${theme.colors.success};
        color: ${theme.colors.white};
        border: 0px;
        padding: 10px;
    }
`

export const AlertStyle = styled(Alert)`
    background-color: ${theme.colors.white} !important;
    color: ${theme.colors.primary} !important;
    border: 1px solid ${theme.colors.primary} !important;
`

export const ModalBodyScrollable = styled(ModalBody)`
    overflow-y: scroll !important;
    max-height: 50vh;
`

export const MiniHr = styled(Hr)`
    width: 10%;
    color: #ffffff;
    border-top: 1px dashed ${theme.colors.primary};
    margin: 25px auto;
`