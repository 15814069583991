import React from "react";
import parse from "html-react-parser";

import {
    ButtonPrimary,
    ButtonNeutral,
    AlertStyle,
    ModalBodyScrollable,
} from "../../../components/style";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@bootstrap-styled/v4";

const ModalCopy = (props) => {

    const handleCloseModal = () => props.setModalState(!props.modalState);

    // const str = props.textToCopy;
    // const arr = str.split(/<br \/>/);
    // const resultArr = [];
    
    // arr.forEach((item) => {
    //     resultArr.push(item);
    //     resultArr.push(<br />);
    // });

    const parsedText = parse(props.textToCopy, { trim: true });
    const formattedText = props.textToCopy.split("<br />").join("\n");


    const handleCopyTxt = () => {


        navigator.clipboard.writeText(formattedText.replace(/^\s+|\s+$/g, ""))
        // .then(() => {
            // Alert the user that the action took place.
            // Nobody likes hidden stuff being done under the hood!
            props.setAlertState(true);
        // }));

    };

    return (
        <Modal size="lg" isOpen={props.modalState} toggle={() => handleCloseModal()}>
            <ModalHeader toggle={() => handleCloseModal()}>{props.pageName}</ModalHeader>
            <ModalBodyScrollable>
                {/* <div id="content">
 */}
                    {/* {props.textToCopy.replace(/^\s+|\s+$/g, "")} */}

                    {/* <p style={{ whiteSpace: "pre-line" }}> */}
                    <p>
                        {parsedText}
                        {/* {props.textToCopy.split("<br />").join("\n")} */}
                    </p>
                {/* </div> */}



            </ModalBodyScrollable>

            <ModalBody>
                <AlertStyle
                    isOpen={props.alertState}
                >
                    Texto copiado para a área de transferência.
                </AlertStyle>
            </ModalBody>
            
            <ModalFooter>
                <ButtonPrimary onClick={handleCopyTxt}>Copiar</ButtonPrimary>
                <ButtonNeutral onClick={handleCloseModal}>Fechar</ButtonNeutral>

            </ModalFooter>
        </Modal>


    )
}

export default ModalCopy;