import React from 'react';
import './App.css';

import Layout from './components/layout';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  } from "react-router-dom";

import Home from './pages/home';
import NativesChamadas from './pages/tools/natives-chamadas';
import ParamUrl from './pages/tools/param-url';


function App() {


  return (

    <>
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/natives-chamadas" element={<NativesChamadas />} />
            <Route path="/param-url" element={<ParamUrl />} />
          </Routes>
        </Layout>
      </Router>
    </>

  );
}

export default App;
