import React from "react";
import Menu from "./menu";

import { ToolsIcon } from "./icons";

const Sidebar = () => {

    return(
        <>
            <nav id="sidebar">
                <div className="sidebar-header">
                    <h3>G.Lab Tools</h3>
                    <ToolsIcon />
                </div>

                <ul className="list-unstyled components">
                    <Menu />
                </ul>


            </nav>
        </>

    )
}

export default Sidebar;